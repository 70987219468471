* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f2f2f4;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.MuiTooltip-tooltip {
  font-size: 1.1rem;
}
